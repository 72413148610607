import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class TaskDTO
{
    id?: Nullable<number>;
    guid?: Nullable<string>;
    name?: Nullable<string>;
    task_id?: Nullable<number>;
    file_name?: Nullable<string>;
    priority?: Nullable<number>;

    constructor({ id, guid, name}: {id?: Nullable<number>, guid?: Nullable<string>, name?: Nullable<string>}) {
        this.id = id;
        this.guid = guid;
        this.name = name;
    }
}

export default class Task extends AbstractBaseEntity
{
    private id?: Nullable<number>;
    private guid?: Nullable<string>;
    private name: string;

    constructor(
        id: Nullable<number>,
        guid: Nullable<string>,
        name: string
    ) {
        super();
        this.id = id;
        this.guid = guid;
        this.name = name;
    }

    static create(dto: TaskDTO): Task
    {
        return new Task(
            dto.id,
            dto.guid,
            dto.name
        );
    }

    setId(id: number): void
    {
        this.id = id;
    }

    getId(): number
    {
        return this.id;
    }

    setGuid(guid: string): void
    {
        this.guid = guid;
    }

    getGuid(): string
    {
        return this.guid;
    }

    setName(name: string): void
    {
        this.name = name;
    }

    getName(): string
    {
        return this.name;
    }
}
